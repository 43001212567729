<template>
   <section class="cont dataSurvey-box">
      <!-- 面包屑 user_data member_data -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>SCRM</el-breadcrumb-item>
            <el-breadcrumb-item>数据概况</el-breadcrumb-item>
            <el-breadcrumb-item>{{echartState === 'user_data' ? '用户概况' : '会员概况'}}</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框  -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class='search-item'>
                  <label>营业时间：</label>
                  <el-date-picker
                        v-model="pickerTime" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
                  </el-date-picker>
               </el-row>
               <el-button @click="queryEvent" class="bg-gradient">搜索</el-button>
               <el-button @click="resetEvent">重置</el-button>
            </el-row>
         </el-row>
         <!-- echarts折线图 -->
         <el-row  style='padding: 40px 0px;'>
            <div id="dataSurvey_chart" style="width:92%;height:500px;"></div>
         </el-row>
         <ul class="dataShow-box">
            <li>
               <span>本日新增</span>
               <span class="solid-box">{{dayCount}}</span>
            </li>
            <li>
               <span>本月新增</span>
               <span class="solid-box">{{monthCount}}</span>
            </li>
            <li>
               <span>平台总数</span>
               <span class="solid-box">{{platformTotal}}</span>
            </li>
         </ul>
      </el-row>
   </section>
</template>

<script>
import { dateFactory } from '@/common/js/common';
import { urlObj } from "../../../../api/interface";
import {mapState} from "vuex";
export default {
   name: "userData",
   data(){
      return{
         echartState:'',         // 概况类型
         pickerTime:[],          // 营业时间
         pickerOptions: {
            disabledDate(time) {
               return time.getTime() >= Date.now();
            }
         },
         total:[],                   //echarts全部/会员
         wechat_official:[],       //echarts微信公众号
         douyin_applet:[],         //echarts抖音小程序
         wechat_applet:[],         //echarts微信小程序
         // number:[],              //echarts
         timeData:[],         //echarts时间


         dayCount:'',         //本日新增
         monthCount:'',         //本月新增
         platformTotal:'',    //平台总数
         param:{},            // 数据
      }
   },
   mounted() {
      this.echartState = this.$route.params.echartsData
      this.chartEvent(true)
   },
   computed: {...mapState(['hotelInfo'])},
   watch:{
      hotelInfo:{
         handler(newVal,oldVal){
            if(newVal.id !== oldVal.id && oldVal){
               this.chartEvent(true)
            }
         }
      },
      $route(to) {
         if (to.name === 'dataSurvey'){
            // console.log(this.$route.params.echartsData)
            this.echartState = this.$route.params.echartsData
            this.chartEvent(true)
         }
      },
   },
   methods: {
      // member_data
      getData(bool,state){
         return new Promise((resolve,reject)=>{
            let url = ''
            let param = {}
            if(this.echartState === 'user_data'){
               url = urlObj.getUserTotal
               param.companyId = this.hotelInfo.storeId
               param.userSources = 'WECHAT_OFFICIAL,DOUYIN_APPLET,WECHAT_APPLET'
               delete param.hotelId
            }else{
               param.hotelId = this.hotelInfo.id
               url = urlObj.getMemberTotal
              if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
                param.hotelId = this.storeId
                param.companyId = this.hotelInfo.storeId
              }
            }
            /* WECHAT_OFFICIAL  微信公众号
               DOUYIN_APPLET 抖音小程序
               WECHAT_APPLET  微信小程序 */
            if(bool) param.flag = true
            if(!bool) param.flag = false
            // 时间范围
            let today = dateFactory.getDistanceToday(0,true)
            if( this.pickerTime === null || this.pickerTime.length === 0 ){
               if(this.echartState === 'user_data'){
                  // 用户概况 默认30天数据
                  param.bTime = dateFactory.getDistanceToday(-29,false) + ' 00:00:01'
                  param.eTime = today
               }else{
                  // 用户概况 默认7天数据
                  param.bTime = dateFactory.getDistanceToday(-6,false) + ' 00:00:01'
                  param.eTime = today
               }
            }else{
               param.bTime = this.pickerTime[0] + ' 00:00:01'
               // 结束日期为本日的话 时分秒为此时 否则为 23:59:59
               if(this.pickerTime[1] === dateFactory.getDistanceToday(0,false)) param.eTime = today
               else param.eTime = this.pickerTime[1] + ' 23:59:59'
            }
           // 暂时注释
           /* if(state){
               console.log(state)
               let userSources = ['WECHAT_OFFICIAL','DOUYIN_APPLET','WECHAT_APPLET']
              if(!state[1]) userSources.splice(0,1)
              if(!state[2]) userSources.splice(1,1)
              if(!state[3]) userSources.splice(2,1)
               param.userSources = userSources.join(',')
            }else{
               param.userSources = 'WECHAT_OFFICIAL,DOUYIN_APPLET,WECHAT_APPLET'
            } */
             this.$axios.post(url, param).then(res => {
               if (res.success) {
                  resolve(res.records)
               }
            })
         })
      },
      //获取/设置echarts数据
      async chartEvent(bool){
         let chartDom = document.getElementById('dataSurvey_chart');
         let myChart = this.$echarts.init(chartDom);
         let dataSurvey_chart = {};

         let seriesData = []  // 数据
         let legendData = [] // lagend
         let data = await this.getData(bool)
         if(data){
           if(bool){
              if(data.hasOwnProperty('dayCount')) this.dayCount = data.dayCount         //本日新增
              if(data.hasOwnProperty('monthCount')) this.monthCount = data.monthCount     //本月新增
              if(data.hasOwnProperty('total')) this.platformTotal = data.total          //平台总数
           }
            /*
             eachars时间 timeData

             全部/会员 total
             微信公众号 WECHAT_OFFICIAL
             抖音小程序 DOUYIN_APPLET
             微信小程序 WECHAT_APPLET
             */
            let members = data.members
            for(const k in members){
               this[k.toLowerCase()] = [] // 重置数据
               if(k === 'total') this.timeData = [] // 重置时间
               members[k].forEach(item=>{
                  if(k === 'total') this.timeData.push(item.date)
                  this[k.toLowerCase()].push(item.totalCount) // 设置seriesData中data数据
               })
            }
            if(this.echartState === 'user_data'){
               seriesData = [
                  { name: '全部',type: 'line',data: this.total },
                  { name: '微信公众号',type: 'line',data: this.wechat_official },
                  { name: '抖音小程序',type: 'line',data: this.douyin_applet },
                  { name: '微信小程序',type: 'line',data: this.wechat_applet },
               ]
            }else{
               seriesData = [{ name: '会员',type: 'line',data: this.total}]
            }
         }
         seriesData.forEach( item => { legendData.push(item.name) })

        // 折线图
         dataSurvey_chart = {
            title: {
               text: '数量',
               // subtext: '订单数',
            },
            tooltip: {
               trigger:'item',
               show:true,
            },
            grid: {
               left: '0%',
               right: '4%',
               bottom: '3%',
               containLabel: true
            },
            data: ['订单数1'],
            legend: {
               data: legendData, //['微信', '抖音']/['会员']
               // selected:{
               //    'X':true,
               //    'Y':false,
               //    'Z':false
               // }
            },
            xAxis: {
               type: 'category',
               data: this.timeData
            },
            yAxis: {
               type: 'value',
            },
            series:seriesData
         };
         // myChart.on('legendselectchanged', this.legendEvent) // lagend点击触发事件
         dataSurvey_chart && myChart.setOption(dataSurvey_chart,true);
      },
      // 暂时注释
      // legendEvent(params){
      //    let select_value = Object.values(params.selected) ;
      //    this.getData(false,select_value)
      // },
      // 搜索
      queryEvent(){
         this.chartEvent(false)
      },
      // 重置
      resetEvent(){
         this.pickerTime = []
         this.chartEvent(false)
      },
   },
}
</script>

<style scoped lang="scss">
.dataSurvey-box{
   //.tab-box{
   //   width: 207px;
   //   ::v-deep .el-tabs__header {
   //      margin: 0 0 31px;
   //      border-bottom: 1px solid #E3E4E5;
   //      .el-tabs__item{
   //         font-size: 18px;
   //         font-family: Microsoft YaHei;
   //         font-weight: 400;
   //         color: #2D2D2D;
   //      }
   //      .is-active{
   //         font-size: 18px;
   //         font-weight: bold;
   //         color: #2577E3;
   //      }
   //   }
   //}
   .dataShow-box{
      list-style: none;
      li{
         display: flex;
         margin-bottom: 10px;
         span{
            display: inline-block;
            width: 85px;height: 40px;
            line-height: 40px;
         }
      }
      .solid-box{
         border: 1px solid #dedede;
         text-align: center;

      }
   }
}
</style>
